.dashboard {
  margin: 20px;
}

.card {
  width: 1300px;
  height: auto;
  /* height: 2000px; */
  margin: 0 auto;
  justify-items: center;
}
.bookCard-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;

  color: var(--bs-card-cap-color);
  background-color: #f8f9fa;
  /* background-color: #526d82; */

  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  justify-content: center;
  border-radius: 24px;
}
.dvh-100 {
  /* height: 'window.innerHeight - 127px' !important; */
  height: auto !important;
  /* height: 100vh !important; */
}

.nav-tabs {
  border-bottom: none;
  justify-content: center;
  border-radius: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  background-color: #526d82;
}

.nav-item {
  margin-bottom: 10px;
}

.bookNav-link {
  background-color: #9db2bf;
  /* background-color: #f8f9fa; */
  border: none;
  color: #333;
  border-radius: 10;
  margin: 20px;
  width: 100%;
  height: 60px;
  border-radius: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bookNav-link.active {
  background-color: #27374d;
  /* color: #00a652; */
  color: #fff;
}

.bookNav-link:hover {
  background-color: #f8f9fa;
  /* color: #00a652; */
  color: #333;
}
.bookCard-body {
  padding: 20px;
}

h5 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: bold;

  font-size: 16px;
}

/* ----------------------------------------------------------------
                              Booking CARD
  -----------------------------------------------------------------*/

.bookCard {
  width: 600px;
  margin: auto;
  padding: 20px;
  /* background-color: #d7c0ae;  */
  /* background-color: #f2f2f2; */
  background-color: #9db2bf;
  border-radius: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.bookCard table {
  width: 100%;
}

.bookCard td {
  padding: 8px;
  /* border-bottom: 1px solid #ddd; */
  border: 1px solid #ddd;
  background-color: #f8f9fa;
}

.bookCard td:first-child {
  font-weight: bold;
  width: 120px;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 14px;
  border: 1px solid #ccc;
}

.form-label {
  font-weight: bold;
}
.btn-primarry {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
  background-color: #afd7de;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.btn-primary {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}
.text-primary {
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.bookCol-lg-5 {
  flex: 0 0 auto;
  width: auto;
  /* width: 119.66666667%; */
}

/* ----------------------------------------------------------------
                              UPDATE Booking 
  -----------------------------------------------------------------*/

.updateBookingCard {
  width: auto;
  /* width: 1200px; */
  margin: auto;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.updateBookingCard h2 {
  text-align: center;
  margin-bottom: 20px;
}

.updateBookingCard form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.updateBookingCard form label {
  display: block;
}

.updateBookingCard form input {
  width: 100%;
  padding: 10px;
}

.updateBookingCard form button {
  padding: 10px;
  background-color: #0d6efd;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* justify-items: center; */
}

.updateBookingCard form button:hover {
  background-color: #0b5ed7;
}

.bookingTable {
  width: 100%;
  border-collapse: collapse;
}

.bookingTable th,
.bookingTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.bookingTable th {
  background-color: #f2f2f2;
}

.bookingTable tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.bookingTable tbody tr:hover {
  background-color: #e9e9e9;
}

.updateCNStatusForm {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.updateCNStatusForm h2 {
  text-align: center;
  margin-bottom: 20px;
}

.updateCNStatusForm form {
  display: flex;
  flex-direction: column;
}

.updateCNStatusForm label {
  margin-bottom: 10px;
}

.updateCNStatusForm input,
.updateCNStatusForm select {
  padding: 10px;
  border-radius: 4px;
}

.updateCNStatusForm select {
  margin-bottom: 10px;
}

.updateCNStatusForm button {
  padding: 10px;
  background-color: #0d6efd;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.updateCNStatusForm button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.select-primary {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
  background-color: #afd7de;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* .select-primary:hover {
  background-color: #0056b3;
} */